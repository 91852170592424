import React from "react"
import styles from "./homeHero.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export default props => (
  <section>
    <div
      className={`${styles.homeHeroImage} text-center px-1`}
      style={{ backgroundImage: `url(${props.image})` }}
    >
      <img src={props.logo} className="img-fluid px-3" />
      <h1 className="text-white my-1">{props.name}</h1>
      <h2 className="text-white">{props.headline}</h2>
      <p className="text-white my-2">{props.text}</p>
      <AniLink
        paintDrip
        hex="#ea4c12"
        duration={0.75}
        to="/buy/"
        className="btn"
      >
        How to Buy
      </AniLink>
    </div>
  </section>
)
